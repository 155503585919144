html {
  font-size: 14px;
}

body {
  color: #2F373A !important;
  background-color: #FAFAFA !important;
}

.bg-dark {
  background-color: #2F373A !important;
}

.navbar {
  line-height: 1;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark {
  background-color: #2F373A;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
  color: white;
}

.navbar-brand svg {
  height: 20px;
  width: auto;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #00B395;
}

.navbar .form-control {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #919190;
  border-radius: 0.25em;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5em;
  line-height: 1.3;
  outline: none;
  appearance: none;
  transition: box-shadow 0.1s ease-in;
}

.nav.flex-column .nav-link.active,
.nav.flex-column .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.card {
  background: white;
  border: 1px solid #c0c0be;
  border-radius: 0.25em;
  margin-bottom: 1em;
}

.table-striped tr:nth-of-type(2n + 1) {
  background-color: white !important;
}

.page-item .page-link {
  color: rgba(36, 94, 199, 0.9);
}
.page-item.active .page-link {
  background-color: rgba(36, 94, 199, 0.9);
  border-color: rgba(36, 94, 199, 0.9);
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-poiner {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0 0 7px 1px rgb(0, 0, 0, 0.22);
}

.badge-primary {
  background-color: #00B395;
}

.link-primary {
  color: #00B395;
  transition: 0.2s;
}

.link-primary:hover {
  color: #387E6A;
}
